import React, { useEffect, useState } from 'react'
import { 
  Button, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogContentText, 
  DialogTitle,
  Typography,
  Box,
  Container
} from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { useSearchParams } from 'react-router-dom'

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#dc004e',
    },
  },
})

export default function UnsubscribePage() {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [isUnsubscribed, setIsUnsubscribed] = useState(false)
  const [searchParams] = useSearchParams();
  const [email, setEmail] = useState("");
  useEffect(() => {
    setEmail(searchParams.get('email'))
  }, []);

  const handleUnsubscribe = () => {
    setIsDialogOpen(true)
  }

  const confirmUnsubscribe = async() => {
    // Here you would typically make an API call to unsubscribe the user
    console.log(email);
    try {
        const response = await fetch(`/api/mmj/unsubscribe`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email }),
        });
        if (response.status == 200) {
            setIsUnsubscribed(true);
            setIsDialogOpen(false);
        } else {
            // setIsUnsubscribed(true)
            setIsDialogOpen(false);
        }
    } catch (error) {
        console.error('Error:', error);
    }
    
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="sm">
        <Box 
          sx={{
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            p: 2,
          }}
        >
          <Typography variant="h4" component="h1" gutterBottom>
            Unsubscribe from Our Job Leads
          </Typography>
          {isUnsubscribed ? (
            <Typography variant="body1" color="success.main" sx={{ mb: 2 }}>
              Wish you all the best for the future!
            </Typography>
          ) : (
            <>
              <Typography variant="body1" sx={{ mb: 3 }}>
                We're sorry to see you go. If you unsubscribe, you'll no longer receive our list of jobs.
              </Typography>
              <Button 
                variant="contained" 
                color="error" 
                onClick={handleUnsubscribe}
                fullWidth
                sx={{ maxWidth: { sm: '200px' } }}
              >
                Unsubscribe
              </Button>
            </>
          )}

          <Dialog
            open={isDialogOpen}
            onClose={() => setIsDialogOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Confirm Unsubscribe
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to unsubscribe from our Job leads? You can always resubscribe later.
              </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'flex-start', p: 2, flexDirection: { xs: 'column', sm: 'row' } }}>
              <Button 
                onClick={() => setIsDialogOpen(false)} 
                color="primary"
                variant="outlined"
                fullWidth
                sx={{ mb: { xs: 1, sm: 0 }, mr: { sm: 1 } }}
              >
                Cancel
              </Button>
              <Button 
                onClick={confirmUnsubscribe} 
                color="secondary"
                variant="contained"
                fullWidth
                autoFocus
              >
                Yes, Unsubscribe
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Container>
    </ThemeProvider>
  )
}

